import { Menu } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'gatsby';
import React from 'react';

const StyledMenu = styled(Menu)`
  border-bottom: none;
`;

function HeaderMenu() {
  let current_tag;
  if (typeof window === 'undefined') {
    current_tag = '';
  } else if (window.location.pathname === '/') {
    current_tag = 'home';  // homepage
  } else {
    current_tag = /(?<=\/)[^/]+/.exec(window.location.pathname)[0];
  }

  const [ selected_key, set_selected_key ] = useState(current_tag);

  const handleSelect = ({ key }) => {
    if (key !== 'cv' || key !== 'old-site') {
      set_selected_key(key);
    }
  };

  return (
    <StyledMenu mode='horizontal' 
      activeKey={[selected_key]}
      defaultActiveFirst={[selected_key]}
      selectedKeys={[selected_key]}
      defaultSelectedKeys={[selected_key]}
      onSelect={handleSelect}
    >
      <Menu.Item key='home' title='Home'>
        <Link to='/'>Home</Link>
      </Menu.Item>
      <Menu.Item key='download' title='Download'>
        <Link to='/download'>Download</Link>
      </Menu.Item>
      <Menu.Item key='doc' title='Documentation'>
        <Link to='/doc'>Documentation</Link>
      </Menu.Item>
      <Menu.Item key='update-log' title='Update Log'>
        <Link to='/update-log'>Update Log</Link>
      </Menu.Item>
      <Menu.Item key='wechat-article' title='公众号文档'>
        <Link to='/wechat-article'>公众号文档</Link>
      </Menu.Item>
    </StyledMenu>
  )
}

export default HeaderMenu;